<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('prep_reports')" :is-filter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('prep_reports')" :is-filter="false">
                </HeaderMobile>
            </template>
            <div class="row data-form">
                <div class="col-12">
                    <div class="mb-4">
                        <h6 class="text-uppercase mb-0">{{ $t('prep_reports') | toUpperCase }}</h6>
                    </div>
                </div>
                <div class="col-12 col-md-12">
                    <ValidationObserver ref="form">
                        <div class="row">
                            <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <ValidationProvider name="report" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('report')">
                                        <multi-selectbox :multiple="false" :validate-error="errors[0]"
                                                         :options="reportOptions"
                                                         v-model="form.report">
                                        </multi-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <ValidationProvider name="academic_year" :rules="form.report!=9?'required':''" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('academic_year')">
                                        <academic-years-selectbox
                                            :validate-error="errors[0]"
                                            v-model="form.academic_year">
                                        </academic-years-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <ValidationProvider name="module_id" :rules="form.report!=9?'required':''" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('module')">
                                        <module-selectbox
                                            :academic_year="form.academic_year"
                                            :validateError="errors[0]"
                                            value-type="id"
                                            v-model="form.module_id">
                                        </module-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div v-if="form.report != 7 && form.report != 8" class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <ValidationProvider name="level" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('level')">
                                        <parameter-selectbox
                                            code="prep_levels"
                                            :multiple="true"
                                            :validateError="errors[0]"
                                            v-model="form.level">
                                        </parameter-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div v-if="form.report != 7  && form.report != 8" class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <ValidationProvider name="class" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('class')">
                                        <parameter-selectbox
                                            code="prep_class"
                                            :multiple="true"
                                            :validateError="errors[0]"
                                            v-model="form.class">
                                        </parameter-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div v-if="form.report != 7 && form.report != 8" class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <ValidationProvider name="start_date" :rules="form.report==9?'required':''" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('start_date')">
                                        <select-date     :validation-error="errors[0]"
                                                         v-model="form.start_date"></select-date>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div v-if="form.report != 7 && form.report != 8" class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <ValidationProvider name="end_date"   :rules="form.report==9?'required':''"  v-slot="{valid, errors}">
                                    <b-form-group :label="$t('end_date')">
                                        <select-date     :validation-error="errors[0]"
                                                         v-model="form.end_date"></select-date>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <b-form-group>
                                    <b-button variant="primary" @click="send">{{ $t('download_report') }}</b-button>
                                </b-form-group>
                            </div>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout"
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import qs from 'qs'
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import ModuleSelectbox from "@/components/interactive-fields/ModuleSelectbox";
import PrepStudentService from "@/services/PrepStudentService";
import PrepExamNotes from "@/services/PrepExamNotes";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import SelectDate from "@/components/interactive-fields/SelectDate.vue";

export default {
    components: {
        SelectDate,
        MultiSelectbox,
        AcademicYearsSelectbox,
        AppLayout,
        Header,
        HeaderMobile,
        ParameterSelectbox,
        ModuleSelectbox,
        ValidationProvider,
        ValidationObserver,
    },
    metaInfo() {
        return {
            title: this.$t('prep_reports')
        }
    },
    data() {
        return {
            form: {},
            reportOptions: [
                {value: '1', text: this.$t('all_exam_scores')},
                {value: '2', text: this.$t('exam_averages')},
                {value: '3', text: this.$t('success_rate')},
                {value: '4', text: this.$t('proficiency_exam_entry_status')},
                {value: '5', text: this.$t('prep_attendance_status')},
                {value: '6', text: this.$t('passed_exams_report')},
                {value: '7', text: this.$t('success_status_report')},
                {value: '8', text: this.$t('student_absence_report')},
                {value: '9', text: this.$t('passed_absence_report')},
            ]
        }
    },
    methods: {
        async send() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                let reportName = this.getOptionText(this.reportOptions, this.form.report);
                if (this.form.report == '1') {
                    if (this.checkPermission('prepexamscore_report')) {
                        PrepExamNotes.scoreReport(this.form).then(response => {
                            this._downloadFile(response, reportName);
                        }).catch(e => {
                            this.showErrors(e, this.$refs.form, true);
                        });
                    } else {
                        this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                    }

                } else if (this.form.report == '2') {
                    if (this.checkPermission('prepexamscore_averagereport')) {
                        PrepExamNotes.averageReport(this.form).then(response => {
                            this._downloadFile(response, reportName);
                        }).catch(e => {
                            this.showErrors(e, this.$refs.form, true);
                        });
                    } else {
                        this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                    }

                } else if (this.form.report == '3') {
                    if (this.checkPermission('prepexamscore_successratereport')) {
                        PrepExamNotes.successRateReport(this.form).then(response => {
                            this._downloadFile(response, reportName);
                        }).catch(e => {
                            this.showErrors(e, this.$refs.form, true);
                        });
                    } else {
                        this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                    }
                } else if (this.form.report == '4') {
                    if (this.checkPermission('prepexam_statusreport')) {
                        PrepExamNotes.statusReport(this.form).then(response => {
                            this._downloadFile(response, reportName);
                        }).catch(e => {
                            this.showErrors(e, this.$refs.form, true);
                        });
                    } else {
                        this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                    }

                }else if (this.form.report == '5') {
                    if (this.checkPermission('prepattendance_statusreport')) {
                        PrepExamNotes.prepAttendanceStatusReport(this.form).then(response => {
                            this._downloadFile(response, reportName);
                        }).catch(e => {
                            this.showErrors(e, this.$refs.form, true);
                        });
                    } else {
                        this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                    }

                }else if (this.form.report == '6') {
                    if (this.checkPermission('prepexam_statusreport')) {
                        PrepExamNotes.prepExamStatusReport(this.form).then(response => {
                            this._downloadFile(response, reportName+ ".xlsx");
                        }).catch(e => {
                            this.showErrors(e, this.$refs.form, true);
                        });
                    } else {
                        this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                    }

                }
                else if (this.form.report == '7') {
                    if (this.checkPermission('prepexam_successstatusreport')) {
                        const config = {
                            params: {
                                filter: this.form
                            },
                            paramsSerializer: (params) => qs.stringify(params, {encode: false})
                        }

                        delete config.params.filter['report']

                        PrepExamNotes.getSuccessStatusReport(config)
                            .then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message))
                                this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                            })
                            .catch(e => {
                                this.showErrors(e, this.$refs.form, true);
                            })
                    } else {
                        this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                    }
                }
                else if (this.form.report == '8') {
                    if (this.checkPermission('prepstudent_attendancereport')) {
                        PrepStudentService.getAbsenceReport(this.form)
                            .then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message))
                                this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                            })
                            .catch(e => {
                                this.showErrors(e, this.$refs.form, true);
                            })
                    } else {
                        this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                    }
                }
                else if (this.form.report == '9') {
                    if (this.checkPermission('prepattendance_instructorattendancereport')) {
                        PrepStudentService.passedAbsenceReport(this.form)
                            .then(response => {
                                this._downloadFile(response,this.$t('passed_absence_report')+'.xlsx')
                            })
                            .catch(e => {
                                this.showErrors(e, this.$refs.form, true);
                            })
                    } else {
                        this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                    }
                }
            }
        }
    }
};
</script>

